import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import endless_sphere from '../../content/assets/resources/endless_sphere.webp'
import open_inverter from '../../content/assets/resources/open_inverter.webp'
import electrotransport from '../../content/assets/resources/electrotransport.webp'
import electromobile from '../../content/assets/resources/electromobile.webp'
import electroavtosam from '../../content/assets/resources/electroavtosam.webp'
import electricmotorcycleforum from '../../content/assets/resources/electricmotorcycleforum.webp'
import ev_conversion_book from '../../content/assets/resources/ev_conversion_book.jpg'
import ev_podcast from '../../content/assets/resources/ev_podcast.webp'
import resources_page from '../../content/assets/page_images/resources_page.webp'
import pw_case from '../../content/assets/resources/pw_case.webp'

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const siteSlogan = data.site.siteMetadata.slogan
    const frontmatter = {
      slug: this.props.location.pathname.replace(/[^\w\d-]/g, '')
    };


    return (
      <Layout location={this.props.location} title={siteTitle} slogan={siteSlogan} >
         <SEO
          title="Useful Resources for EV enthusiasts"
          description="The internet is full of interesting EV resources. Over the years we've collected some interesting resources and websites on the topic of electric vehicles. EV forums, EV blogs, podcasts, and books. "
          image={resources_page}
          frontmatter={frontmatter}
        />
        <main>
          <h1 className="blog-heading">Resources</h1>
          <p>Welcome to the Power Watcher Resources page!</p>
          <p>On this page you'll find interesting resources for EV enthusiasts, PowerWatcher upgrades and other stuff.</p>
          <h2 className="underlined-heading">PowerWatcher Accessories</h2>
          <div className="grid-wrapper">
            <div className="item-grid">
              <a className="resources-link" href="https://www.thingiverse.com/thing:4088738">
                <div className="single-item">
                  <figure className="item-image-wrapper">
                    <img className="item-image" src={pw_case} alt="Endless sphere forums"/>
                  </figure>
                  <div className="item-caption">
                    <h4 className="item-heading">PowerWatcher 3 Case</h4>
                    <p className="item-text">Robin Wainwright designed an awesome case for PowerWatcher 3 along with the RS485 voltage module and current sensor. Boards are mounted using M3 bolts and washers, self-tapping into the plastic. Lids are friction fit, so you may have to over/undersize the lid just slightly for a good fit depending on your printer.</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <h2 className="underlined-heading">Websites & Forums</h2>
          <div className="grid-wrapper">
            <div className="item-grid">
              <a className="resources-link" href="https://endless-sphere.com/forums/">
                <div className="single-item">
                  <figure className="item-image-wrapper">
                    <img className="item-image" src={endless_sphere} alt="Endless sphere forums"/>
                  </figure>
                  <div className="item-caption">
                    <h4 className="item-heading">Endless Sphere</h4>
                    <p className="item-text">Endless Sphere is an online electric vehicle forum where  thousands of electric bicycle enthusiasts discuss ebike topics. It’s a community and a gathering place where people come to share ideas, talk shop, show off their creations and help beginners get into the world of electric bicycles.</p>
                  </div>
                </div>
              </a>
              <a className="resources-link" href="https://openinverter.org/forum/">
                <div className="single-item">
                  <figure className="item-image-wrapper">
                    <img className="item-image" src={open_inverter} alt="Open inverter forum"/>
                  </figure>
                  <div className="item-caption">
                    <h4 className="item-heading">Open Inverter</h4>
                    <p className="item-text">This forum has lots of information about various BMS systems, electric vehicle, and energy storage projects. It's a place where you can discuss inverter related topics or tell the world about your project.</p>
                  </div>
                </div>
              </a>
              <a className="resources-link" href="https://electrotransport.ru/ussr/index.php">
                <div className="single-item">
                  <figure className="item-image-wrapper">
                    <img className="item-image" src={electrotransport} alt="Electrotransport forum"/>
                  </figure>
                  <div className="item-caption">
                    <h4 className="item-heading">Elektrotransport</h4>
                    <p className="item-text">The forum electrotransport.ru contains a lot of useful information on the selection and operation of batteries and accumulators, their various modifications. Here you can find a discussion thread on almost any topic, from creating an electric car to DIY electric bicycles.</p>
                  </div>
                </div>
              </a>
              <a className="resources-link" href="http://electricmobile.org.ua/">
                <div className="single-item">
                  <figure className="item-image-wrapper">
                    <img className="item-image" src={electromobile} alt="Electric vehicle website"/>
                  </figure>
                  <div className="item-caption">
                    <h4 className="item-heading">Electromobile</h4>
                    <p className="item-text">If you want to create an electric car with your own hands, then you should definitely visit this website. It contains many useful tips, recommendations,  examples, and other important information on the subject of DIY EV cars.</p>
                  </div>
                </div>
              </a>
              <a className="resources-link" href="https://electroavtosam.com.ua/">
                <div className="single-item">
                  <figure className="item-image-wrapper">
                    <img className="item-image" src={electroavtosam} alt=" Creative EV DIY and conversion club"/>
                  </figure>
                  <div className="item-caption">
                    <h4 className="item-heading">Electroautosam</h4>
                    <p className="item-text">Electroautosam club brings together engineers passionate about creating and tuning electric vehicles. The main goal of the club is the popularization of technical creativity in the field of design and construction of electric autonomous vehicles.</p>
                  </div>
                </div>
              </a>
              <a className="resources-link" href="http://electricmotorcycleforum.com/">
                <div className="single-item">
                  <figure className="item-image-wrapper">
                    <img className="item-image" src={electricmotorcycleforum} alt=" Electric motorcycle forum"/>
                  </figure>
                  <div className="item-caption">
                    <h4 className="item-heading">ElectricMotorcycleForum</h4>
                    <p className="item-text"> A friendly community where you can talk about electric motorcycles and scooters with like-minded people to learn more and compare notes. It is a great forum to check out for current news and conversation related to electric motorcycles!</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <h2 className="underlined-heading">Books</h2>
          <div className="grid-wrapper">
            <div className="item-grid">
              <a className="resources-link" href="https://www.amazon.com/Electric-Vehicle-Conversion-Handbook-Motorcycles/dp/1557885680">
                <div className="single-item">
                  <figure className="item-image-wrapper">
                    <img className="item-image" src={ev_conversion_book} alt=" Electric vehicle conversion book"/>
                  </figure>
                  <div className="item-caption">
                    <h4 className="item-heading">The Electric Vehicle Conversion Handbook</h4>
                    <p className="item-text">A guide on how to convert any gas- or diesel-powered vehicle to electric power. Includes ownership advantages, basic EV operation, subsystems, components, basic EV operation, project vehicles, and conversion kits.</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <h2 className="underlined-heading">Podcasts</h2>
          <div className="grid-wrapper">
            <div className="item-grid">
              <a className="resources-link" href="https://worldpodcasts.com/ev-podcast/">
                <div className="single-item">
                  <figure className="item-image-wrapper">
                    <img className="item-image" src={ev_podcast} alt="Electric vehicle podcast"/>
                  </figure>
                  <div className="item-caption">
                    <h4 className="item-heading">Electric Vehicle Podcast</h4>
                    <p className="item-text">The EV Podcast is the place to hear about Electric Vehicles. Also – solar power, ebikes, autonomous vehicles and sustainability. EV enthusiast Dee West (solPR/ChargeNet) and EV converter and enthusiast Theo Gibson (EV Builders/Gibson Solutions Ltd) talk news, rumours and more.</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </main>
      </Layout>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        slogan
      }
    }
  }
`
